<template>
  <div>
    <div class ="topbanner flexRow" style="margin-bottom:10px">
      <img src="@/assets/img/5d265653aef7f06427449aa54265263.png" style="width:60px;height:60px" alt="">
      <div class = "flexCol" style="margin-left:10px;flex:1">
        <div class = "titfont" style="margin-top:0px">拼团抢购活动</div>
        <div class = "secfont">基于多人拼团活动延伸的玩法，例如：10人团的活动，组团成功后，仅6人可拼中活动商品，
          剩余4人自动退款，且获得优惠券、购物金、现金等奖励。商家可以为指定的拼团活动开启该玩法（注意：拼团活动相关设置需要在【多人拼团】活动中编辑）  
          <el-button type="text" style="padding:0px;margin:0px;font-size:12px" @click="$router.push({path:'/market/sellGroupBox/sellGroup'})">前往管理拼团活动</el-button>
        </div>
      </div>
    </div>
    <el-card style="margin-top:10px">
      <div class = "flexRow">
        <div class = "flexRow">
          <div class = "keyfont">关键字：</div>
          <el-input v-model="keywords" style="width:200px" placeholder="商品名称"></el-input>
        </div>
        <div class = "flexRow" style="margin:0px 16px">
          <div class = "keyfont">活动状态：</div>
          <el-select v-model="activestate">
            <el-option v-for="(v,i) in activestatelist" :key="i" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div>
        <div class = "flexRow">
          <el-button type="primary" style="margin-right:16px" @click="currentPage=1,gettablelist()">查询</el-button>
          <buttonPermissions :datas="'spellgroupbuypush'">
            <el-button type="primary" @click="newpush">添加</el-button>
          </buttonPermissions>
        </div>
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading="tableloading">
          <el-table-column prop="" label="商品" width="400">
            <template slot-scope="scope">
              <div class = "flexRow">
                <img :src="imgurl+scope.row.ImgUrl " style="width:64px;height:64px" alt="">
                <div class = "flexCol" style="margin-left:5px;flex:1">
                  <div class = "fonthidden2">
                    {{scope.row.Name}}
                  </div>
                  <div style="width:100%;flex:1">
                    <div class = "fonthidden ordernum">
                      {{scope.row.ProductNo}}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="GroupMemberNum" label="成团人数"></el-table-column>
          <el-table-column prop="SnapUpMemberCount" label="抢中人数"></el-table-column>
          <el-table-column prop="" label="活动时间" width="200">
            <template slot-scope="scope">
              <div>
                {{scope.row.StartTime}} <span>至</span>
              </div>
              <div>
                {{scope.row.EndTime}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="ActivityStateShow" label="状态"></el-table-column>
          <el-table-column prop="OpenSpellGroupSnapUpTime" label="开启时间"></el-table-column>
          <el-table-column prop="" label="操作" width="180">
            <template slot-scope="scope">
              <buttonPermissions :datas="'spellgroupbuyedit'" style="margin-right:10px"
               v-if="scope.row.ActivityStateShow=='进行中'||scope.row.ActivityStateShow=='未开始'">
                <el-button type="text" @click="edit(scope.row)">编辑</el-button>
              </buttonPermissions>
              <el-button type="text" style="margin-right:10px" v-else @click="lookactive(scope.row)">查看</el-button>
              <el-button type="text" style="margin-right:10px" @click="look(scope.row)">查看活动</el-button>
              <el-dropdown v-if="scope.row.ActivityStateShow!='未开始'&&((Showwinners&&scope.row.ActivityStateShow!='未开始')||
                (Showadvance&&scope.row.ActivityStateShow!='未开始'&&scope.row.ActivityStateShow!='已结束')||
                (Shownotice&&scope.row.ActivityStateShow!='未开始'&&scope.row.ActivityStateShow!='已结束'))">
                <el-button type="text">更多</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.ActivityStateShow!='未开始'">
                    <buttonPermissions :datas="'spellgroupbuywinners'" @isShow='(e)=>getbtnshow(e,"Showwinners")'>
                      <div style="color:#409EFF;" @click="openwinners(scope.row)">中奖名单</div>
                    </buttonPermissions>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.ActivityStateShow!='未开始'&&scope.row.ActivityStateShow!='已结束'">
                    <buttonPermissions :datas="'spellgroupbuyadvancewin'" @isShow='(e)=>getbtnshow(e,"Showadvance")'>
                      <div style="color:#409EFF;" @click="advancewin(scope.row)">提前开奖</div>
                    </buttonPermissions>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.ActivityStateShow!='未开始'&&scope.row.ActivityStateShow!='已结束'">
                    <buttonPermissions :datas="'spellgroupbuypremierenotice'" @isShow='(e)=>getbtnshow(e,"Shownotice")'>
                      <div style="color:#409EFF;" @click="setnotice(scope.row)">开播通知</div>
                    </buttonPermissions>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top:10px;text-align:right" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :visible.sync="winnnersShow" title="中奖名单" width="800px" v-loading="winloading" class="dialog-body-paddingTop-10" @closed="winnerlist=[]">
      <div class="winnerlist" v-if="winnerlist&&winnerlist.length">
        <div class="demobox" v-for="(v,i) in winnerlist" :key="i">
          <img :src="v.WxHeadUrl||defaule_header" class="demoimg" alt="">
          <div style="flex:1;width:0">
            <div class="ellipsis demoname">{{v.WxNickname}}</div>
            <div class="demoorder" style="margin-top:7px" @click="toorderdetail(v.OrderNo)">订单：{{v.OrderNo}}</div>
          </div>
        </div>
      </div>
      <div class="nowinner" v-else>
        <div class="nowinner-title">暂无人中奖</div>
        <div class="nowinner-sec">提示：组团成功后才会开奖哦~</div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="noticeShow" title="开播通知" width="900px" v-loading="noticeloading" class="dialog-body-paddingTop-10">
      <div class="noticepage">
        <div class="title" style="margin-left:20px">视频号直播间开播后，给参与本场多人拼团抢购活动的客户发送公众号通知。</div>
        <div class="content">
          <div class="con-left">
            <div class="con-flex">
              <div class="label-font">通知标题：</div>
              <div class="con-font">服务完成通知</div>
            </div>
            <div class="con-flex">
              <div class="label-font star">前言：</div>
              <div class="con-font">
                <el-input v-model="noticemsg.PremiereNoticePreface" placeholder="最多30个字" maxlength="30" style="width:100%"></el-input>
              </div>
            </div>
            <div class="con-flex">
              <div class="label-font star">服务项目：</div>
              <div class="con-font">
                <el-input v-model="noticemsg.PremiereNoticeServiceProject" placeholder="最多30个字" maxlength="30"></el-input>
              </div>
            </div>
            <div class="con-flex">
              <div class="label-font">时间：</div>
              <div class="con-font">
                通知发送时间（示例：2022-10-21  20:00:00）
              </div>
            </div>
            <div class="con-flex">
              <div class="label-font star">备注：</div>
              <div class="con-font">
                <el-input v-model="noticemsg.PremiereNoticeRemark" placeholder="最多30个字" maxlength="30"></el-input>
              </div>
            </div>
            <div class="con-flex">
              <div class="label-font">通知链接：</div>
              <div class="con-font">
                默认跳转页面，引导进入直播间
                <el-popover
                  placement="bottom"
                  trigger="click">
									<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/spellgroupbuy-lookdemo.png" style="width:180px" alt="">
                  <el-button type="text" style="margin:0px;padding:0px" slot="reference">查看示例</el-button>
                </el-popover>
              </div>
            </div>
          </div>
          <div class="con-right">
            <el-card :body-style="{ padding:' 0px' }" class="right-box">
              <div class="box-top">
                <div class="right-title">服务完成通知</div>
                <div class="right-sec">{{noticemsg.PremiereNoticePreface}}</div>
                <div style="margin-top:25px">
                  <div class="right-label">
                    <div class="right-labelfont">服务项目：</div>
                    <div class="ellipsis" style="line-height:1.5;">{{noticemsg.PremiereNoticeServiceProject}}</div>
                  </div>
                  <div class="right-label">
                    <div class="right-labelfont">时间：</div>
                    <div class="ellipsis" style="line-height:1.5;">2022-10-21 20:00:00</div>
                  </div>
                  <div class="right-label">
                    <div class="right-labelfont">备注：</div>
                    <div class="ellipsis" style="line-height:1.5">{{noticemsg.PremiereNoticeRemark}}</div>
                  </div>
                </div>
              </div>
              <div class="box-bottom">
                <div class="bottom-between">
                  <div class="flex-center">
								    <img :src="imgurl+'/image/pagesharetoplogo.png'" style="width:20px;height:20px;margin-right:5px" alt="">
                    <div class="bottom-font">小程序名称</div>
                  </div>
                  <div class = "bottom-between">
                    <img :src="imgurl+'/image/pagesharebottom.png'" style="width:13px;height:13px;margin-right:5px" alt="">
                    <div class="bottom-font">小程序</div>
                    <i class = "el-icon-arrow-right" style="font-size:16px"></i>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div class="flex flex-justify-center">
          <el-button style="margin-right:10px" @click="noticeShow=false">取消</el-button>
          <el-popover placement="top" width="300" trigger="click" v-model="btnvisible">
            <div>
              <div class="flex">
                <i class="el-icon-warning" style="color:#E6A23CFF;margin:2px 7px 0px 0px"></i>
                <div>
                  请仔细核对本次发送的通知内容。确认推送，系统将立即给参与本场拼团抢购的客户发送公众号通知。
                </div>
              </div>
              <div class="flex flex-justify-between" style="margin-top:10px;">
                <div></div>
                <div>
                  <el-button size="small" type="text" @click="btnvisible=false">取消</el-button>
                  <el-button size="small" type="primary" @click="savepush(1)">确认推送</el-button>
                </div>
              </div>
            </div>
            <el-button type="primary" style="margin-right:10px" plain slot="reference">立即推送</el-button>
          </el-popover>
          <el-popover placement="top" width="300" trigger="click" v-model="btnjustsend">
            <div>
              <div class="flex">
                <i class="el-icon-warning" style="color:#E6A23CFF;margin:2px 7px 0px 0px"></i>
                <div>
                  本次操作，仅保存编辑的通知内容，并不会给客户推送公众号通知。
                </div>
              </div>
              <div class="flex flex-justify-between" style="margin-top:10px;">
                <div></div>
                <div>
                  <el-button size="small" type="text" @click="btnjustsend=false">取消</el-button>
                  <el-button size="small" type="primary" @click="savepush(2)">好的</el-button>
                </div>
              </div>
            </div>
            <el-button type="primary" style="margin-right:10px" slot="reference">仅保存</el-button>
          </el-popover>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  activitySpellGroupactivitySpellGroupSnapUpManager,
  activitySpellGroupactivitySpellGroupSnapUpAwardList,
  activitySpellGrouppremiereNoticeInfo,
  activitySpellGrouppremiereNoticeSave
} from '@/api/TurnTomySelf'
import config from "@/config"
import buttonPermissions from '@/components/buttonPermissions';
export default {
  components: {
    buttonPermissions
  },
  data () {
    return {
			defaule_header:config.DEFAULT_HEADER,
      imgurl:config.IMG_BASE,
      keywords:'',
      activestate:null,
      activestatelist:[
        {value:null,label:'全部'},
        {value:0,label:'未开始'},
        {value:1,label:'进行中'},
        {value:2,label:'已结束'},
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:null,

      Showwinners:true,
      Showadvance:true,
      Shownotice:true,

      rowmsg:{},
      winnnersShow:false,
      winnerlist:[],
      noticeShow:false,
      noticemsg:{
        PremiereNoticePreface:'你参与的拼团抢购活动正在直播间开奖，快来参与吧!',
        PremiereNoticeServiceProject:'开播提醒',
        PremiereNoticeRemark:'点击通知快速进入直播间',
      },
      btnvisible:false,
      btnjustsend:false,
      noticeloading:false,
      winloading:false,
    }
  },
  created () {
    this.gettablelist()
  },
  methods:{
    getbtnshow(value,btnname){
      this.$nextTick(()=>{
        this[btnname] = value
        console.log(this[btnname])
      })
    },
    async savepush(e){
      if(!this.noticemsg.PremiereNoticePreface){
        this.$message.error('前言内容不可为空')
        return
      }else if(!this.noticemsg.PremiereNoticeServiceProject){
        this.$message.error('服务项目内容不可为空')
        return
      }else if(!this.noticemsg.PremiereNoticeRemark){
        this.$message.error('备注内容不可为空')
        return
      }
      try{
        this.noticeloading = true
        this.btnvisible = false
        this.btnjustsend = false
        let num = await e
        let data = {
          ...this.noticemsg,
          IsOnlySave:num==2,
          ActivityFullId:this.rowmsg.Id,
          noError:true
        }
        let res = await activitySpellGrouppremiereNoticeSave(data)
        // res.ErrorCode = 20000020
        // res.IsSuccess = false
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.noticeShow = false
          this.gettablelist()
        }else if(res.ErrorCode==20000020){
          this.$confirm('推送失败。商城未绑定视频号ID，无法推送视频号直播间开播通知。', '提示', {
            confirmButtonText: '去绑定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({
              name:'liveCutIn',
              params:{
                tab:2
              }
            })
          }).catch(() => {});
        }else{
          this.$message.error(res.Message)
        }
      }finally{
        this.noticeloading = false
      }
    },
    setnotice(row){
      this.noticeShow = true
      this.rowmsg = row
      this.getnotice()
    },
    async getnotice(){
      try{
        this.noticeloading = true
        let res = await activitySpellGrouppremiereNoticeInfo()
        if(res.IsSuccess){
          this.noticemsg = res.Result
        }
      }finally{
        this.noticeloading = false
      }
    },
    advancewin(row){
      this.$router.push({
        name:'sellGroup',
        params:{
          Id:row.Id
        }
      })
    },
    toorderdetail(e){
      this.$router.push({
        path:'/order/orderDetailpage',
        query:{
          Id:e
        }
      })
    },
    openwinners(row){
      this.rowmsg = row
      this.winnnersShow = true
      this.getwinnerlist()
    },
    async getwinnerlist(){
      this.winloading = true
      try{
        let data = {
          Id:this.rowmsg.Id
        }
        let res = await activitySpellGroupactivitySpellGroupSnapUpAwardList(data)
        if(res.IsSuccess){
          this.winnerlist = res.Result
        }
      }finally{
        this.winloading = false
      }
    },
    look(e){
      this.$router.push({
        path:'/market/sellGroupBox/looksellGroupActive',
        query:{
          Id:e.Id,
        }
      })
    },
    lookactive(e){
      this.$router.push({
        path:'/market/spellgroupbuy/details',
        query:{
          Id:e.Id,
          lookover:true,
          state:e.ActivityStateShow=='已结束'?2:0
        }
      })
    },
    edit(e){
      console.log(e)
      this.$router.push({
        path:'/market/spellgroupbuy/details',
        query:{
          Id:e.Id,
          state:e.ActivityStateShow=='已结束'?2:0
        }
      })
    },
    newpush(){
      this.$router.push({
        path:'/market/spellgroupbuy/details',
      })
    },
    handleSizeChange(e){
      this.currentPage=1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Keywords:this.keywords,
          ActivityState:this.activestate,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        }
        let res = await activitySpellGroupactivitySpellGroupSnapUpManager(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
          // console.log(this.tablelist.map((v)=>{
          //   return v.ActivityStateShow 
          // }))
        }
      }finally{
        this.tableloading = false
      }
    },
  },
}
</script>

<style lang = "less" scoped>
.noticepage{
  .title{
    font-size: 14px;
    color: #999999;
    line-height: 1.5;
  }
  .content{
    display: flex;
    justify-content: space-between;
    .con-left{
      width: 60%;
      margin-right: 10px;
      .con-flex{
        display: flex;
        margin-bottom: 22px;
        .label-font{
          width: 100px;
          white-space: nowrap;
          flex-shrink: 0;
          font-size: 14px;
          color: #606266;
          line-height: 36px;
          /* border:1px solid black; */
          text-align: right;
          margin-right:10px;
        }
        .star.label-font::before{
            content: '*';
            color: #f56c6c;
            margin-right: 4px;
        }
        .con-font{
          font-size: 14px;
          color: #909399;
          line-height: 36px;
          flex:1
        }
      }
    }
    .con-right{
      width: 40%;
      padding: 10px;
      .right-box{
        border: 1px solid #EBEEF5;
        border-radius: 5px;
        .box-top{
          padding: 15px;
          .right-title{
            font-size: 16px;
            color: #303133;
            line-height: 1.5;
            margin-top: 6px;
          }
          .right-sec{
            font-size: 14px;
            color: #666666;
            line-height: 1.5;
            margin-top: 10px;
          }
          .right-label{
            display: flex;
            .right-labelfont{
              white-space: nowrap;
              width: 80px;
              color: #666666;
              line-height: 1.5;
              margin-bottom: 5px;
            }
          }
        }
        .box-bottom{
          border-top: 1px solid #EBEEF5;
          padding: 15px;
          .bottom-between{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .flex-center{
              display: flex;
              align-items: center;
              .bottom-font{
                color: #666666;
                font-size: 14px;
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
  }
}
.winnerlist{
  /* border:1px solid black; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 500px;
  overflow-y: auto;
  .demobox{
    width: 49%;
    flex-shrink: 0;
    /* border:1px solid black; */
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 0px;
    margin-bottom: 10px;
    .demoimg{
      width: 60px;height: 60px;
      border-radius: 3px;
      margin-right: 5px;
    }
    .demoname{
      color: #606266;
    }
    .demoorder{
      color: #909399;
      &:hover{
        color: #409eff;
        cursor: pointer;
      }
    }
  }
}
.nowinner{
  .nowinner-title{
    font-size: 14px;
    color: #606266;
    /* font-weight: bold; */
    text-align: center;
  }
  .nowinner-sec{
    color: #909399;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  }
}
.flex{
  display: flex;
}
.flex-justify-between{
  justify-content: space-between;
}
.flex-justify-center{
  justify-content: center;
}
.flexRow{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* align-items: flex-start; */
}
.flexCol{
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}
.content {
		display: flex;
		overflow: hidden;
		padding: 15px;
		background-color: white;
	
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}
.topbanner{
  background: white;
  padding: 10px 20px;
  .titfont{
    font-size: 14px;
    color: #303133;
    line-height: 1.5;
    font-weight: bold;
  }
  .secfont{
    color: #606266;
    font-size: 12px;
    line-height: 1.5;
    margin-top:5px;
  }
}
.keyfont{
  white-space: nowrap;
  font-size: 14px;
  color: #606266;
  line-height: 1.5;
  font-weight: bold;
}
.fonthidden{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.fonthidden2{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.ordernum{
  font-size: 14px;
  color: #999999;
  /* border:1px solid black; */
  max-width:99%;
}
</style>